<template>
  <b-card>
    <b-card-title>
      Configuración general
    </b-card-title>
    <validation-observer ref="settingGeneral">
      <b-form>
        <b-row>
          <!-- IGV -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="IGV"
              label-for="igv"
            >
              <validation-provider
                #default="{ errors }"
                name="IGV"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="settings.igv"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                  placeholder="igv"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Exchange rate -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Tipo de cambio"
              label-for="exchange_rate"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipo de cambio"
                rules="required"
              >
                <b-form-input
                  id="exchange_rate"
                  v-model="settings.exchangeRate"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Terminos y condiciones"
              label-for="terms_conditions"
            >
              <quill-editor
                v-model="settings.termsAndConditions"
                :options="snowOption"
              />
            </b-form-group>
          </b-col>

          <!-- login button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    quillEditor,
    BCardTitle,
    ToastificationContent,
  },
  data() {
    return {
      passValue: '',
      username: '',
      settings: {
        igv: '',
        exchangeRate: '',
        termsAndConditions: '',
      },
      required,
      snowOption: {
        theme: 'snow',
      },
      content: '',
    }
  },
  created() {
    this.fetchSettingGeneral()
  },
  methods: {
    async fetchSettingGeneral() {
      try {
        const response = await this.$http.get('admin/setting/general')

        const { exchange_rate: exchangeRate, terms_conditions: termsAndConditions, ...rest } = response.data.payload.results
        this.settings.exchangeRate = exchangeRate
        this.settings.termsAndConditions = termsAndConditions
        Object.assign(this.settings, rest)
      } catch (e) {
        console.log(e)
      }
    },
    validationForm() {
      this.$refs.settingGeneral.validate().then(async success => {
        if (success) {
          try {
            await this.$http.put('admin/setting/general/update', this.settings)
            this.showToast({ title: 'Actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          } catch (e) {
            console.log(e)
            this.showToast({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
          }
        }
      })
    },
    showToast({ title, icon, variant }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
